import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '', 
    loadChildren: './auth/auth.module#AuthModule'
  },

  {
    path: 'dashboard', 
    loadChildren: './main/main.module#MainModule'
  },

  {
    path: 'dispenser', 
    loadChildren: './checkout/checkout.module#CheckoutModule'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' , useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
